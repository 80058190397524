<template>
  <div>
    <h2 class="text-h2 pt-4 pl-3 pb-8">Help</h2>
    <v-row>
      <v-col
        cols="6"
        sm="9"
      >
        <v-expansion-panels class="ml-3" accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>Plan - search vehicle</v-expansion-panel-header>
            <v-expansion-panel-content>
              Type in the registration number of the vehicle you need in the search box towards
              the right of the header. This will filter the vehicles shown in the main panel.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Plan - select date</v-expansion-panel-header>
            <v-expansion-panel-content>
              To view the booked work for a day, click on the date in the small calendar top left
              of the screen. This will show all booked work for the selected day. You may find it
              helpful to zoom out - (Click CTRL and -) depending on the size of your screen.
              (Click CTRL and 0) to set the screen zoom back to normal.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Plan - add job</v-expansion-panel-header>
            <v-expansion-panel-content>
              This screen enables you to plan work and view a calendar of existing booked work.
              To add a job, choose from the jobs on the left hand panel, you can filter these by
              typing in the job number at the top. Click on the Orange icon to the left of the job
              number. In the panel which opens you can set the date, start time, vehicle and
              personnel for the job. If the vehicle has been associated with personnel these will
              be added automatically. You can change these if you need to - simply use the drop down
              list, or type at the top of the list to search.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Plan - update job</v-expansion-panel-header>
            <v-expansion-panel-content>
              To update a job click on the orange job card in the main calendar, this will show you
              the job details. From here you can click on update or delete, or click cancel to close
              the panel. After clicking on update you will see the current date, time, vehicle and
              personnel for the job - you can change any of these as required. Then click the
              orange save button to finish.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Plan - delete job</v-expansion-panel-header>
            <v-expansion-panel-content>
              To delete a job click on the orange job card in the main calendar, this will show you
              the job details. From here you can click on delete. You will be shown a warning asking
              you to confirm you wish to delete the planned work - please note this will not delete
              the job itself, but it will delete the planned work from the calendar and from
              SafetyPAL.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Plan - vehicle unavailable</v-expansion-panel-header>
            <v-expansion-panel-content>
              If a vehicle is unavailable for a given time slot it will show in the main calendar in
              red. If you try to book work in for a date when the vehicle is unavailable you will be
              shown a warning that the vehicle is out of service for that time. However, you can
              still book the work for that vehicle for that time if you need to.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Plan - worker unavailable</v-expansion-panel-header>
            <v-expansion-panel-content>
              If a worker is unavailable for the date you have selected you will be shown a warning
              that the worker is unavailable for the selected time period. However, you can still
              book them in for the work if you need to.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Vehicles - view details</v-expansion-panel-header>
            <v-expansion-panel-content>
              In the vehicles screen you will see a list of vehicles on the left hand side. You can
              filter this list by entering the registration in the vehicle search in the header
              bar. Click on the orange vehicle icon to the left of the registration to open the
              information panel. From here you can view the vehicle details.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Vehicles - set default workers</v-expansion-panel-header>
            <v-expansion-panel-content>
              From the vehicle information panel you can see the current list of default workers
              for a vehicle and set new ones as required. Click on the Set Default Workers button
              and update each worker as required. These will then be automatically added to a
              planned job when you select the vehicle in plan.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Vehicles - set default workers</v-expansion-panel-header>
            <v-expansion-panel-content>
              From the vehicle information panel you can see the current list of default workers
              for a vehicle and set new ones as required. Click on the Set Default Workers button
              and update each worker as required. These will then be automatically added to a
              planned job when you select the vehicle in plan.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Vehicles - add scheduled maintenance
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              From the vehicle information panel you can click on the add scheduled maintenance
              button. This will open a calendar, click the start date for the maintenance period,
              then click the end date - you will see this highlighted on the calendar. (If the
              period is for one day click the same date twice). After setting the dates you can
              set the start and end times for the maintenance period and add a brief description
              of why the vehicle is not available.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Map</v-expansion-panel-header>
            <v-expansion-panel-content>
              The map functionality will be added shortly.
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Help',
};
</script>
